<template>
  <button type="button" @click="clickHandler($event)">
    <slot></slot>
  </button>
</template>

<script>
  export default {
    props: {
      target: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        isFullscreen: false,
        fullscreenEnabled: document.fullscreenEnabled || document.mozFullScreenEnabled || document.documentElement.webkitRequestFullScreen,
      };
    },
    methods: {
      requestFullscreen(element) {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        }
      },
      clickHandler(e) {
        const targetNode = document.querySelector(this.target);

        if (targetNode) {
          if (this.fullscreenEnabled) {
            // Fullscreen API is supported
            this.requestFullscreen(targetNode);
          } else {
            // Fullscreen API is unsupported
            // Try to find the source url
            let url = targetNode.getAttribute('src');

            if (!url) {
              const source = targetNode.querySelector('source');

              if (source && source.getAttribute('src')) {
                url = source.getAttribute('src');
              }
            }

            // If we've have a source url, Open element in new window
            if (url) {
              window.open(url, '_blank');
            }
          }
        }

        e.preventDefault();
      },
    },
  };
</script>
