var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    { staticClass: "c-scroll-down", on: { click: _vm.smoothScroll } },
    [
      _vm._t("default"),
      _vm._v(" "),
      _c("icon", { class: _vm.iconClass, attrs: { name: "chevron" } })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }