<template>
  <div class="l-cookie-notice px-3 py-2 px-lg-4 py-lg-3 m-sm-2 m-lg-3"
       :class="{ 'd-none': isAccepted }"
       ref="cookieNotice"
       v-if="!isAccepted">
    <div class="cms-text text-14 text-white font-weight-medium">
      <slot></slot>
      <button class="js-cookie-close btn-accept btn-reset text-uppercase font-weight-bold text-white d-inline-flex align-items-center mt-2"
              type="button"
              @click="acceptCookies">
        <slot name="btn-accept"></slot>
      </button>
      <button class="js-cookie-close btn-close btn-reset d-flex align-items-center justify-content-center"
              type="button"
              @click="acceptCookies">
        <slot name="btn-close"></slot>
      </button>
    </div>
  </div>
</template>

<script>
  import { gsap } from 'gsap';

  export default {
    data() {
      return {
        // True if net, false if unset
        isAccepted: !!localStorage.getItem('gdpr'),
      };
    },
    methods: {
      acceptCookies(e) {
        e.preventDefault();
        localStorage.setItem('gdpr', 'true');
        this.animateOut();
      },
      animateOut() {
        gsap.to(this.$el, {
          duration: 0.5,
          ease: 'power1.in',
          y: '50%',
          opacity: 0,
          onComplete: this.updateState,
        });
      },
      updateState() {
        this.isAccepted = localStorage.getItem('gdpr');
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "src/scss/vendor/bootstrap/_imports.scss";

  .l-cookie-notice {
    $width: 22rem;

    position: fixed;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: $zindex-modal-backdrop - 1;
    font-weight: 400;
    background: $dark;

    @include media-breakpoint-up(sm) {
      width: $width;
    }

    a {
      color: $white;
      font-weight: 600;
    }

    a,
    button {
      transition: $transition-base;

      &:hover,
      &:focus {
        opacity: 0.7;
      }
    }

    .btn-accept {
      @include font-size(12px);
      letter-spacing: 2px;

      .icon {
        $icon-size: 16px;

        width: $icon-size;
        height: $icon-size;
      }
    }

    .btn-close {
      $icon-size: 12px;

      position: absolute;
      width: $icon-size;
      height: $icon-size;
      top: 0;
      right: 0;
      margin: 15px;

      .icon {
        width: $icon-size;
        height: $icon-size;
      }
    }
  }

</style>
