<!-- <icon name="arrow" class="fill-white"></icon> -->
<template>
  <svg class="icon" :class="prefixName">
    <use :xlink:href="href"></use>
  </svg>
</template>

<script>
  import { root } from '../utils/paths';

  export default {
    props: {
      name: String,
    },
    computed: {
      prefixName() {
        return `icon-${this.name}`;
      },
      href() {
        return `${root}/img/icons.svg#${this.name}`;
      },
    },
  };
</script>
