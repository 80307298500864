var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("svg", { staticClass: "icon", class: _vm.prefixName }, [
    _c("use", { attrs: { "xlink:href": _vm.href } })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }